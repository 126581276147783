import React from "react"
import { graphql } from 'gatsby'
import { IndexTemplate } from '../templates/indexTemplate'


export default function Home({data}) {

  const index = data.allDataJson.nodes[0].index
  const navbar = data.allDataJson.nodes[0].index.navbar
  const sidebar = data.allDataJson.nodes[0].index.general
  const news = data.allDataJson.nodes[0].index.news
  const welcomeBanner = data.allDataJson.nodes[0].index.welcomeBanner
  const emergency = data.allDataJson.nodes[0].index.emergency
  const services = data.allDataJson.nodes[0].index.services
  const vetinfo = data.allDataJson.nodes[0].index.vetinfo
  const transportinfo = data.allDataJson.nodes[0].index.transportinfo
  const payment = data.allDataJson.nodes[0].index.payment
  const operation = data.allDataJson.nodes[0].index.operation
  const closureandgrief = data.allDataJson.nodes[0].index.closureandgrief
  const footer1 = data.allDataJson.nodes[0].index.general
  const footer2 = data.allDataJson.nodes[0].index.footer
  return (
    <IndexTemplate
      bannerData={index.banner}
      whoweareData={index.whoweare}
      navbar={navbar}
      sidebar={sidebar}
      news={news}
      welcomeBanner={welcomeBanner}
      emergency={emergency}
      emergenyTransport={emergency.emergencyTransportInfo}
      services={services}
      vetinfo={vetinfo}
      transportinfo={transportinfo}
      payment={payment}
      operation={operation}
      closureandgrief={closureandgrief}
      footer1={footer1}
      footer2={footer2}
    />
  )
}

export const query = graphql`
{
  allDataJson {
    nodes {
      index {
        banner {
          src {
            childImageSharp {
              gatsbyImageData(
                placeholder:DOMINANT_COLOR
                width:2560
                height:1080
                transformOptions: {fit:COVER, cropFocus:CENTER}
              )
            }
          }
          alt
        }
        navbar {
          title
          navigationButtons {
            title
            link
          }
        }
        whoweare {
          header
          portrait {
            src {
              childImageSharp{
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  width:1920
                  height:1080
                )
              }
            }
            alt
          }
          slides {
            src {
              childImageSharp{
                gatsbyImageData(
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                  width:1920
                  height:1080
                )
              }
            }
            alt
          }
        }
        general {
          title
          opening {
            details{
              text
            }
            times {
              days
              time
            }
            title
          }
          phone {
            detail
            number
            title
          }
          email {
            title
            adress
          }
          adress {
            title
            street
            zipcode
          }
        }
        news {
          subitems {
            title
            text
          }
        }
        welcomeBanner {
          title
          text
        }
        emergency {
          title
          emergencyInfo {
            button {
              link
              text
            }
            text1
            text2
            title
          }
          emergencyTransportInfo {
            title
            subitems {
              text
            }
          }
        }
        services {
          title
          tiles {
            title
            icon {
              src {
                publicURL
              }
              alt
            }
            text
          }
        }
        vetinfo {
          headline
          advice
          title
          imageDog {
            src {
              publicURL
            }
            alt
          }
          imageCat {
            alt
            src {
              publicURL
            }
          }
          subitems {
            text
            icon {
              alt
              src {
                publicURL
              }
            }
          }
        }
        transportinfo {
          title
          subitems {
            title
            icon {
              alt
              src {
                publicURL
              }
            }
            image {
              alt
              src {
                publicURL
              }
            }
          }
        }
        payment {
          cost {
            title
            text
          }
          fee {
            title
            text
            subtitle
          }
          options {
            title
            ec
            bar
          }
        }
        operation {
          subitems {
            text
            icon {
              alt
              src {
                publicURL
              }
            }
          }
          title
        }
        closureandgrief {
          title
          list {
            img {
              src {
                childImageSharp{
                  gatsbyImageData(
                    width:600
                    height:600
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
              alt
            }
            header
            text
          }
        }
        footer {
          contactTitle
          openingTitle
          name
          slogan
          copyright
          endorsement
        }
      }
    }
  }
}
`