import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components'
import Arrow from '../data/icons/arrow.svg'

const Container = styled.div`
overflow: hidden;
background-color:var(--tmain-light2);
color:var(--tgrey);
border-radius:10px;
max-width:500px;
width:75%;

margin: 25px 10px 0 10px;
padding:0;

& li {
    font-family:Raleway;
    font-size:1.125em;
    margin-bottom:20px;
}

@media(min-width:993px){
    max-width:31.25em;
    & div {
        height: auto;
    }
}
`

const Header = styled.div`
display: flex;
align-items:center;
justify-content:space-around;

& h2 {
    font-family:Hind;
    font-size:1.375em;
    max-width:60%;
    padding:0;
}

& svg {
    width: 2em;
    height: 2em;
    transform: rotateZ(${props => props.dropdown?('180deg'):('0deg')});
    transition: transform 0.5s;
}

@media(min-width:993px){
    & h2 {
        font-size:1.75em;
        max-width:100%;
        text-align:center;
    }
}
`

const Main = styled.div`
&:hover {
    cursor:pointer;
}
`

const Expandable = styled(AnimateHeight)`
display:block;

& ul{
    list-style-type: circle;
}
`

class EmergencyTransport extends Component{
    constructor(props){
        super(props)

        this.state={
            height: 0,
            dropdown: false,
        }
    }
     
    toggle = () => {
    const { height } = this.state;
    
    this.setState({
        height: height === 0 ? 'auto' : 0,
        dropdown: !this.state.dropdown
    });
    };

    render(){
        const { height } = this.state;

        return(
            <Container>
                <Main>
                    <Header dropdown={this.state.dropdown} aria-expanded={ height !== 0 } aria-controls='example-panel' onClick={ this.toggle }>
                        <h2>{this.props.data.title}</h2>
                        <Arrow/>
                    </Header>
                </Main>
                <Expandable
                    id='example-panel'
                    duration={ 500 }
                    height={ height } // see props documentation below
                >
                    <ul>
                    {
                        this.props.data.subitems.map((item, i) => (
                            <li key={i}>{item.text}</li>
                        ))
                    }
                    </ul>
                </Expandable>
            </Container>
        )
    }
}

export default EmergencyTransport;