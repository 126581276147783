import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Header from "./TitleElement"
import Button from "./Button1"
import Paw from '../data/icons/pawOperation.svg'

const Container = styled.div`
position:relative;
width:90%;
max-width:1400px;
margin:auto;

& h2 {
    font-family:Hind;
    font-size:1.125em;

    @media(min-width:993px){
        font-size:22px;
    }
}

& p {
    font-family:open sans;
    font-weight:300;
    font-size:1.125em;

    @media(min-width:993px){
        font-size:1.375em;
    }
}

& button {
    font-size:1.125em;
    font-family:Hind;
    align-self:flex-end;

    @media(min-width:993px){
        font-size:1.25em;
    }
}

& >div:nth-child(n+3){
    display: ${props => props.isExtended?("flex"):("none")};
    opacity:0;
    animation-duration:1.5s;
    animation-timing-function:ease;
    animation-fill-mode: forwards;
}

& >div:nth-child(2n+1){
    animation-name:fadeinleft;
}

& >div:nth-child(2n){
    animation-name:fadeinright;
}

@keyframes fadeinleft {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
        transform: translateX(-60px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes fadeinright {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
        transform: translateX(60px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}
`
const ItemDiv = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
margin:35px auto;
text-align:justify;

animation-delay:${props => (props.index-1)/2}s;

& .image {
    flex-shrink:0;
    width:80%;
    height:80%;
    max-width:350px;
    max-height:350px;
    border-radius:500px;
    margin:50px 0;
    margin-left:0px;
}

@media (min-width:993px) {
    flex-direction:row;

    & .image {
        margin-right:200px;
    }
}
`

const TextDiv = styled.div`
display:flex;
flex-direction:column;

& >div {
    max-width:600px;

    & >div {
        font-family:var(--textfont);

        & ul {
            font-size:1.375em;
        }
    }
}
`

const TextDivSolo = styled.div`
display:flex;
flex-direction:column;

@media (min-width:993px) {
    max-width:67.5%;
}
`

const Paws = styled.div`
position:absolute;
animation:none !important;
opacity:1 !important;
top:0;
left:0;
width:100%;
height:100%;
z-index:-1;

@media(max-width:992px){
    display:none !important;
}

@media(min-width:993px){
    display:block;

    & >svg:nth-child(1){
        position:absolute;
        top:20%;
        left:75%;
    
        width:250px;
        height:250px;

        opacity:0;
        animation:fadein .6s ease 1s;
        animation-fill-mode: forwards;
    }
    
    & >svg:nth-child(2){
        position:absolute;
        top:45%;
        left:70%;
    
        width:350px;
        height:350px;

        opacity:0;
        animation:fadein .6s ease 0.5s;
        animation-fill-mode: forwards;
    }
    
    & >svg:nth-child(3){
        position:absolute;
        top:57%;
        left:15%;
    
        width:500px;
        height:500px;

        opacity:0;
        animation:fadein .6s ease 0s;
        animation-fill-mode: forwards;
    }

    @keyframes fadein {
        0% {
            display: none;
            opacity: 0;
        }
        1% {
            display: block;
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
`

class ClosureAndGrief extends React.Component {
    constructor(props){
        super(props)
        
        this.state = {
            isExtended: false
        }
    }

    toggle(){
        console.log(this.state.isExtended)
        this.setState({isExtended: !this.state.isExtended})
    }

    render() {
        return (
            <Container isExtended={this.state.isExtended}>
                <Header>{this.props.data.title}</Header>
                {
                    this.props.data.list.map((Item,i)=>(
                        <ItemDiv key={i} index={i}>
                            {
                                getImage(Item.img.src)!=null && <GatsbyImage className="image" image={getImage(Item.img.src)} alt={Item.img.alt}/>
                            }
                            {
                                getImage(Item.img.src)!=null && <TextDiv>
                                    <div>
                                        <h2>{Item.header}</h2>
                                        <div dangerouslySetInnerHTML={{__html: Item.text}}/>
                                    </div>
                                    { i == 0 && <Button onClick={this.toggle.bind(this)}>{!this.state.isExtended && "weiter lesen"}{this.state.isExtended && "weniger anzeigen"}</Button>}
                                    { i == this.props.data.list.length-1 && <Button onClick={this.toggle.bind(this)}>{!this.state.isExtended && "weiter lesen"}{this.state.isExtended && "weniger anzeigen"}</Button>}
                                </TextDiv>
                            }
                            {
                                getImage(Item.img.src)==null && <TextDivSolo>
                                    <div>
                                        <h2>{Item.header}</h2>
                                        <div dangerouslySetInnerHTML={{__html: Item.text}}/>
                                    </div>
                                    { i == 0 && <Button onClick={this.toggle.bind(this)}>{!this.state.isExtended && "weiter lesen"}{this.state.isExtended && "weniger anzeigen"}</Button>}
                                    { i == this.props.data.list.length-1 && <Button onClick={this.toggle.bind(this)}>{!this.state.isExtended && "weiter lesen"}{this.state.isExtended && "weniger anzeigen"}</Button>}
                                </TextDivSolo>
                            }
                        </ItemDiv>
                    ))
                }
                <Paws>
                    <Paw/>
                    <Paw/>
                    <Paw/>
                </Paws>
            </Container>
        )
    }
}
export default ClosureAndGrief;