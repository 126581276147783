import React from 'react'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import Arrow from "../data/icons/left-arrow.svg"
import Cross from '../data/icons/cross.svg'


const GlobalStyle = createGlobalStyle`
  html {
    overflow: ${props => props.showmodal==1? ('hidden !important'):('visible')};
  }
`

const Container = styled.div`
position: ${props => props.showmodal==1?('fixed'):('relative')};
top:0;
left:0;
width:${props => props.showmodal==1?('100vw'):('100%')};
height:${props => props.showmodal==1?('100vh'):('auto')};

display:${props => props.showmodal==1?('flex'):('block')};
flex-direction:column;
align-items:center;
justify-content:center;

background-color:${props => props.showmodal==1?('rgba(255,255,255,0.8)'):('none')};
backdrop-filter:${props => props.showmodal==1?('blur(10px)'):('none')};

z-index:${props => props.showmodal==1?('99'):('')};


& #Cross {
    position:absolute;
    top:20px;
    right:20px;
    display:${props => props.showmodal==1?('block'):('none')};

    width:64px;
    height:64px;

    padding:7.5px;
    border-radius:200px;
    border:solid 2px transparent;
    transition:border 0.3s ease;

    &:hover {
        cursor:pointer;
        border:solid 2px var(--tgrey);
    }
}
`

const StyledGatsbyImage = styled(GatsbyImage)`
position:relative;
display:none;
top:0;
left:0;
width:100%
height:100%;

max-width:1920px;
max-height:100%;

border-radius:20px;

animation-duration:.2s;
animation-timing-function:linear;
animation-fill-mode: forwards;

@media(min-width:993px){
    &:hover {
        cursor:${props => props.showmodal==1?(''):('pointer')};
    }
}
`

const ImageContainer = styled.div`
position:relative;
top:0;
left:0;

width:${props => props.showmodal==1?('90%'):('100%')};
height:${props => props.showmodal==1?('auto'):('90%')};
max-height:${props => props.showmodal==1?('80vh'):('90%')};

background-color:var(--twhite);
border-radius:20px;

&:hover {
    & .buttons {
        opacity:1;
    }
}

@media(min-width:993px){
    width:${props => props.showmodal==1?('70%'):('')};

    & .buttons {
        display:block;
    }
}

& .slide:nth-child(${props => props.next+1}) {
    position:absolute;
    animation-name:fade-out;
    z-index:-1;
}

& .slide:nth-child(${props => props.prev+1}) {
    position:absolute;
    animation-name:fade-out;
    z-index:-1;
}

& .slide:nth-child(${props => props.current+1}) {
    position:relative;
    display: block;
    animation-name:fade-in;
    z-index:0;
}

@keyframes fade-in {
    0% {
        position:relative;
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fade-out {
    0% {
        position:absolute;
        display: block;
        opacity: 1;
    }
    99% {
        opacity: 0;
    }
    100% {
        display: none;
        opacity: 0;
    }
}
`

const DotContainer = styled.div`
position:relative;
bottom:0;
display:flex;
justify-content:center;
margin:10px 0 0 0;
width:100%;
height:auto;

& span {
    display:block;
    height:16px;
    width:16px;
    background-color:var(--twhite);
    margin:0 4px;
    border-radius:50%;
    box-shadow:0px 0px 1px 1px rgba(0,0,0,0.4);
    transition:0.4s ease;
    
    &:hover {
        background-color:var(--tgrey-light);
        cursor:pointer;
    }
}

& *:nth-child(${props => props.current+1}) { 
    background-color:var(--tgrey-light);
}

@media(min-width:993px){
    margin:15px 0 0 0;
    
    & span {
        height:20px;
        width:20px;
        margin:0 5px;
    }
}
`

const TextContainer = styled.div`
position:relative;
bottom:0;
width:100%;
height:auto;
margin:0;


& .scaling {
    opacity:0;
    z-index:-1;
}

& .text {
    display:none;
    position:absolute;
    top:0;
    left:0;
    padding:0;
    margin:10px 0 0 0;
    
    transition:opacity .2s linear;
}
  
& .text:nth-child(${props => props.next+1}) {
    display:block;
    opacity:0;
}

& .text:nth-child(${props => props.prev+1}) {
    display:block;
    opacity:0;
}

& .text:nth-child(${props => props.current+1}) {
    display:block;
    opacity:1;
}
`

const RightButton = styled.div`
display:none;
position:absolute;
top:45%;
right:5px;

background-color:rgba(33,33,33,0.8);
padding:8px;
border-radius:10px;

width:50px;
height:50px;
transform:rotateZ(180deg);
fill:var(--twhite);
opacity:${props => props.showmodal==1?('1'):('0')};
transition:opacity .2s ease;

&:hover {
    cursor:pointer;
    fill:var(--tmain);
}
`

const LeftButton = styled.div`
display:none;
position:absolute;
top:45%;
left:5px;

background-color:rgba(33,33,33,0.8);
fill:var(--twhite);
padding:8px;
border-radius:10px;

width:50px;
height:50px;
opacity:${props => props.showmodal==1?('1'):('0')};
transition:opacity .2s ease;

&:hover {
    cursor:pointer;
    fill:var(--tmain);
}
`

const OpenDiv = styled.div`
position:absolute;
display:${props => props.showmodal==1?('none'):('block')};
top:0;
left:0;
width:100%;
height:100%;
border-radius:20px;

@media(min-width:993px){
    &:hover {
        cursor:${props => props.showmodal==1?(''):('pointer')};
    }
}
`

class ModalImageSlideshow extends React.Component {
    constructor(props){
        super(props)

        this.numSlides = (props.slides==null)?(0):(props.slides.length)
        this.x0 = 0

        this.state = {
            currentSlide: 0,
            showModal: false,
        }
    }

    switchSlidesDirectional(s){
        this.setState({
            currentSlide:(((this.state.currentSlide+s)%this.numSlides)+this.numSlides)%this.numSlides,
        })
    }

    switchToSlide(i){
        this.setState({
            currentSlide:i,
        })
    }

    lockSwipe(e) {
        this.x0 = e.touches[0].clientX
    }

    swipe(e) {
        let dx = e.changedTouches[0].clientX - this.x0
        let s = Math.sign(dx)*(-1)
        if(dx!==0){
            this.switchSlidesDirectional(s)
        }
    }

    closeModal(){
        this.setState({showModal: false})
    }

    openModal(){
        if(document.body.scrollWidth>=993){
            this.setState({showModal: true})
        }
    }

    render(){
        return(
            <Container showmodal={this.state.showModal}>
                <ImageContainer
                    showmodal={this.state.showModal?(1):(0)}
                    prev={(this.state.currentSlide-1)%this.numSlides}
                    next={(this.state.currentSlide+1)%this.numSlides}
                    current={this.state.currentSlide}

                    onTouchStart = {(e) => this.lockSwipe(e)}
                    onTouchEnd = {(e) => this.swipe(e)}
                >
                    {
                        this.props.slides.map((item, i) => (
                            <StyledGatsbyImage className="slide" key={i} showmodal={this.state.showModal?(1):(0)} image={getImage(item.src)} alt={item.alt}/>
                        ))
                    }
                    <OpenDiv showmodal={this.state.showModal?(1):(0)} onClick={this.openModal.bind(this)}/>
                    <RightButton className="buttons" showmodal={this.state.showModal?(1):(0)} onClick={this.switchSlidesDirectional.bind(this, 1)}><Arrow/></RightButton>
                    <LeftButton className="buttons" showmodal={this.state.showModal?(1):(0)} onClick={this.switchSlidesDirectional.bind(this, -1)}><Arrow/></LeftButton>   
                </ImageContainer>
                <DotContainer current={this.state.currentSlide}>
                    {
                        this.props.slides.map((item, i) => (
                            <span key={i} onClick={this.switchToSlide.bind(this,i)}></span>
                        ))
                    }
                </DotContainer>
                <TextContainer
                    prev={(this.state.currentSlide-1)%this.numSlides}
                    next={(this.state.currentSlide+1)%this.numSlides}
                    current={this.state.currentSlide}
                >
                    {
                        this.props.slides.map((item, i) => (
                            <p className="text" key={i}>{item.alt}</p>
                        ))
                    }
                </TextContainer>

                <GlobalStyle showmodal={this.state.showModal?(1):(0)}/>
                <Cross id="Cross" onClick={this.closeModal.bind(this)}/>
            </Container>
        )
    }
}

export default ModalImageSlideshow