import React from 'react'
import styled from 'styled-components'
import Arrow from '../data/icons/arrow.svg'

const Container = styled.div`
background-color: transparent;
width: 16.875em;
height: 16.875em;
max-width:90vw;
max-height:90vw;
margin: 16px;
perspective: 1000px;
animation-delay:${props => (props.index-props.visibleCount)/6}s;
`

const Inner = styled.div`
position: relative;
width: 100%;
height: 100%;
text-align: center;
transition: transform 0.8s;
transform-style: preserve-3d;
transform:rotateY(${props => props.rotation}deg);

&:hover {
    cursor:pointer;
    transform:rotateY(${props => (props.rotation>=180) ? (props.rotation) : (props.rotation+20)}deg);
}
`

const Front = styled.div`
position:absolute;
width:100%;
height:100%;
backface-visibility:hidden;
background-color: var(${props => props.currentColor});
border-radius:10px;
display:flex;
align-items:center;
flex-direction:column;

& h2{
  margin-left:10px;
  margin-right:10px;
  font-size:1.5em;
  font-family:var(--textfont);
  font-weight:SemiBold;
  color:var(--tgrey);
}
`

const Icon = styled.img`
position:relative;
width:40%;
height:40%;
top:-5%;
margin:auto;
user-select:none;
`

const Back = styled.div`
background-color: var(${props => props.currentColor});
border-radius:10px;
position:absolute;
width:100%;
height:100%;
backface-visibility:hidden;
transform:rotateY(180deg);
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
font-family:var(--textfont);
font-size:20px;

& li {
  margin-left:20px;
  text-align:left;
}
list-style-position: inside;
font-size:1.25em;
`

const CustomArrow = styled(Arrow)`
position:absolute;
bottom:15px;
right:10px;
width:2.5em;
height:2.5em;
transform:rotateZ(270deg);
color:var(--tgrey)
`


class Tile extends React.Component{
    constructor(props) {
        super(props)
        this.state = {
          rotation: 0,
          color: '',
        }
      }

      componentDidMount() {
          switch(this.props.index%8) {
              case 0:
                this.setState({color: '--tmain-dark'})
                break;
              case 1:
                this.setState({color: '--tmain'})
                break;
              case 2:
                this.setState({color: '--tmain-light'})
                break;
              case 3:
                this.setState({color: '--tmain-light3'})
                break;
              case 4:
                this.setState({color: '--tmain-light3'})
                break;
              case 5:
                this.setState({color: '--tmain-light'})
                break;
              case 6:
                this.setState({color: '--tmain'})
                break;
              case 7:
                this.setState({color: '--tmain-dark'})
                break;
              default:
                console.log("default case triggered");
                break;
          }
      }

      flipCard(){
          (this.state.rotation==0 ? this.setState({rotation:180}) : this.setState({rotation:0}))
      }
      
      render() {
        
        return (
          <Container onClick={this.flipCard.bind(this)} index={this.props.index} visibleCount={this.props.visibleCount}>
            <Inner  rotation={this.state.rotation}>
                <Front currentColor={this.state.color}>
                    <h2>{this.props.data.title}</h2>
                    {
                    (this.props.data.icon.src.publicURL) ? (
                        <Icon src={this.props.data.icon.src.publicURL} alt={this.props.data.icon.alt}/>
                    ) : (<div/>)
                    }
                    <CustomArrow/>
                </Front>
                <Back dangerouslySetInnerHTML={{__html: this.props.data.text}} currentColor={this.state.color}>
                </Back>
            </Inner>
          </Container>
        )
      }
}

export default Tile;