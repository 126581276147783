import React from 'react'
import styled from 'styled-components'
import NewsSubcontainer from './NewsSubcontainer'

const Container = styled.div`
position:relative;
width:100%;

color:var(--tgrey);
`

export default function News(props){

    return(
        <Container>
            {
                props.data.subitems.map((item, i) => (
                    <NewsSubcontainer key={i} data={item}/>
                ))
            }
        </Container>
    )
}