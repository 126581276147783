import React from 'react'
import styled from 'styled-components'
import Button from './Button'
import EmergencyTransport from './EmergencyTransport'
import Header from './TitleElement'
import EmergencyPaw from '../data/icons/emergencyPaw.svg'

const Container = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
`

const Subcontainer = styled.div`
position:relative;
margin-top:50px;

& >div {
    margin:0 25px 40px 25px;
}

& >div:nth-child(1){
    max-width:650px;
    text-align:center;

    & h2 {
        font-family:Hind;
        font-size:1.625em;
        font-weight:bold;
    }

    & p {
        font-family:var(--textfont);
        font-size:1.125em;
        margin:22px auto;

        & p:nth-child(1){
            max-width:80%;
            margin:0 auto;
            
        }
    }
    & .button {
        margin-top:50px;
    }
}
`
const Paws = styled.div`
position:absolute;
display:none;
top:0;
left:0;
width:100%;
height:100%;
z-index:-1;


@media(min-width:993px){
    display:block;

    & >svg:nth-child(1){
        position:absolute;
        top:0%;
        left:0%;
    
        width:100px;
        height:100px;
    }
    
    & >svg:nth-child(2){
        position:absolute;
        top:30%;
        left:-20%;
    
        width:100px;
        height:100px;
    }
    
    & >svg:nth-child(3){
        position:absolute;
        top:55%;
        left:100%;
    
        width:100px;
        height:100px;
    }
}
`

export default function Emergency(props){
    return(
        <Container id={props.id}>
            <Header>{props.data.title}</Header>
            <Subcontainer>
                <div>
                    <h2>{props.data.emergencyInfo.title}</h2>
                    <div dangerouslySetInnerHTML={{__html: props.data.emergencyInfo.text1}}/>
                    <Button className="button" link={props.data.emergencyInfo.button.link}>{props.data.emergencyInfo.button.text}</Button>
                    <div dangerouslySetInnerHTML={{__html: props.data.emergencyInfo.text2}}/>
                </div>
                <Paws>
                    <EmergencyPaw/>
                    <EmergencyPaw/>
                    <EmergencyPaw/>
                </Paws>
            </Subcontainer>
            <EmergencyTransport data={props.data.emergencyTransportInfo}/>
        </Container>
    )
}