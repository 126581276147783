import React from "react"
import styled from "styled-components"
import Paw from "../data/icons/pawNavbar.svg"

const Container = styled.button`
display:flex;
align-items:center;
border:none;
background-color:transparent;
color:var(--tmain);

& span {
    line-height:1;
    font-size:1.25em;
    padding-top:2px;
    border-bottom:2px solid var(--twhite);
}


& svg {
    margin-left:10px;
}

&:hover{
    cursor:pointer;
    & span {
        border-bottom:2px solid var(--tmain);
    }
}
`

export default function Button1(props) {
    return(
        <Container onClick={props.onClick}>
            <span>{props.children}</span>
            <Paw/>
        </Container>
    )
}