import React from 'react'
import styled from 'styled-components'
import Header from './TitleElement'

const Container = styled.div`
position:relative;
width:100%;
`

const Subcontainer = styled.div`
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;

@media(min-width:993px) {
    margin-top: 50px;
    flex-direction:row;
}
`

const ImageContainer = styled.div`
position:relative;
width:80%;
border-radius:10px;
margin: 40px auto;

& >img:nth-child(1) {
    border-radius:10px;
    width:100%;
}

& >div {
    position:absolute;
    top:-40px;
    right:-30px;
    width:100px;
    display:flex;
    align-items:center;
    justify-content:center;
    background-color:var(--twhite);
    border-radius:200px;

    &:before {
        content: '';
        float: left;
        padding-top: 100%;
    }

    & >img {
        width:60%;
    }
}

& span {
    position: absolute;
    bottom: 5px;
    left: 0;
    width:100%;
    text-align:center;
    padding:1% 0;

    font-family:var(--headerfont);
    font-size:1.375em;
    color:var(--twhite);
    background-color:#333333AA;
    border-radius: 0 0 10px 10px;
}

@media(min-width:993px) {
    width:35%;
    margin: 0 25px;

    & >div {
        top:-40px;
        right:-30px;
        width:26%;
    }

    & span {
        font-size:1.625em;
    }
}
`

export default function TransportInfo(props) {
    return (
        <Container>
            <Header>{props.data.title}</Header>
            <Subcontainer>
            {
                props.data.subitems.map((item, i) => (
                    <ImageContainer key={i}>
                        <img src={item.image.src.publicURL} alt={item.image.alt}/>
                        <div>
                            <img src={item.icon.src.publicURL} alt={item.icon.alt}/>
                        </div>
                        <span>{item.title}</span>
                    </ImageContainer>
                ))
            }
            </Subcontainer>
        </Container>
    )
}