import React from 'react'
import styled from 'styled-components'
import { createGlobalStyle } from 'styled-components'
import {GatsbyImage, getImage} from 'gatsby-plugin-image'
import Cross from '../data/icons/cross.svg'


const GlobalStyle = createGlobalStyle`
  html {
    overflow: ${props => props.showmodal==1?('hidden !important'):('visible')};
  }
`

const Container = styled.div`
position: ${props => props.showmodal==1?('fixed'):('relative')};
top:0;
left:0;
width:${props => props.showmodal==1?('100vw'):('100%')};
height:${props => props.showmodal==1?('100vh'):('auto')};

display:${props => props.showmodal==1?('flex'):('block')};
align-items:center;
justify-content:center;

background-color:${props => props.showmodal==1?('rgba(255,255,255,0.8)'):('none')};
backdrop-filter:${props => props.showmodal==1?('blur(10px)'):('none')};

z-index:${props => props.showmodal==1?('99'):('')};

& svg {
    position:absolute;
    top:20px;
    right:20px;
    display:${props => props.showmodal==1?('block'):('none')};

    width:64px;
    height:64px;

    padding:7.5px;
    border-radius:200px;
    border:solid 2px transparent;
    transition:border 0.3s ease;

    &:hover {
        cursor:pointer;
        border:solid 2px var(--tgrey);
    }
}

@media(min-width:993px){
    height:${props => props.showmodal==1?('100vh'):('500px')};
}
`

const StyledGatsbyImage = styled(GatsbyImage)`
position:relative;
width:${props => props.showmodal==1?('90%'):('100%')};
height:${props => props.showmodal==1?('auto'):('100%')};

max-height:95vh;
max-width:1920px;

border-radius:20px;

@media(min-width:993px){
    width:${props => props.showmodal==1?('70%'):('')};

    &:hover {
        cursor:${props => props.showmodal==1?(''):('pointer')};
    }
}
`

const OpenDiv = styled.div`
position:absolute;
display:${props => props.showmodal==1?('none'):('block')};
top:0;
left:0;
width:100%;
height:100%;
border-radius:20px;

@media(min-width:993px){
    &:hover {
        cursor:${props => props.showmodal==1?(''):('pointer')};
    }
}
`

class ModalImageStatic extends React.Component {
    constructor(props){
        super(props)

        this.state = {
            showModal: false,
        }
    }

    closeModal(){
        this.setState({showModal: false})
    }

    openModal(){
        if(document.body.scrollWidth>=993){
            this.setState({showModal: true})
        }
    }

    render(){
        return(
            <Container showmodal={this.state.showModal?(1):(0)}>
                <StyledGatsbyImage showmodal={this.state.showModal?(1):(0)} image={getImage(this.props.data.src)} alt={this.props.data.alt}/>
                <OpenDiv showmodal={this.state.showModal?(1):(0)} onClick={this.openModal.bind(this)}/>
                <GlobalStyle showmodal={this.state.showModal?(1):(0)}/>
                <Cross onClick={this.closeModal.bind(this)}/>
            </Container>
        )
    }
}

export default ModalImageStatic