import React from "react"
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

/*
Props:
slides = array of images that are supposed to be displayed
speed = speed of the transition
delay = before next slide appears
indexButtons = indexButtons are displayed of prop is set
autoSwitchOff = disables automatic switching of slides

z-index: 0-2
*/

const Container = styled.div`
width:100%;
`
const Wrapper = styled.div`
position:relative;
width:100%;
height:25vh;
overflow:hidden;
@media(min-width:993px) {
  height:50vh;
}
`
/*
Props:
current - current slide
next - next slide
prev - previous slide

*/
const ImageWrapper = styled.div`
height:100%;
width:100%;
& .slide {
  display:none;
  position:absolute;
  width:100%;
  height:100%; 
  -webkit-transition:${props => props.speed}s linear;  
  -o-transition:${props => props.speed}s linear  
  transition:${props => props.speed}s linear;
  -o-object-fit:cover;
     object-fit:cover;
  background-color:white;
  object:position:50% 50%;
}
& .slide:nth-child(${props => props.current+1}) {
  display:block;
  margin-left:0;
  opacity:100%;
  z-index:2;
}
& .slide:nth-child(${props => props.next+1}) {
  display:block;
  margin-left:100%;
  z-index:1;
  
}
& .slide:nth-child(${props => props.prev+1}) {
  display:block;
  margin-left:-100%;
  z-index:0;
}
`


class Banner extends React.Component{
    constructor(props){
      super(props);  
      this.itemList = (props.slides==null)?([0]):(props.slides)
      this.numSlides = (props.slides==null)?(0):(props.slides.length) 
      this.autoSwitchOff = (this.props.autoSwitchOff==null)?(false):(true)
      this.switchEnable = true
      this.x0 = 0

      this.delay = (props.delay==null)?(5):(props.delay)
      this.speed = (props.speed==null)?(1):(props.speed)
      this.start = (props.start==null)?(3):(props.start)

      this.state = {
        pos:0,
        interrupt:false
      }
    }
    
    componentDidMount(){
        if(this.autoSwitchOff===false) {
            setTimeout(()=> {
                this.autoSwitch();
            },(this.start)*1000)
        }
    }

    
    autoSwitch() {
      setTimeout(()=> {
        if(this.state.interrupt===false)
          this.switchSlides()
        this.autoSwitch()
        this.setState({interrupt:false})
      },this.delay*1000);     
    }
    
    switchSlides() {
        if(this.state.pos===this.numSlides-1)
          this.setState({pos:0})
        else
          this.setState({pos:this.state.pos+1})
    }

    switchSlidesDirectional(s) {
      if(s<0) {
        if(this.state.pos-1<0)
          this.setState({pos:this.numSlides-1})
        else
          this.setState({pos:this.state.pos-1})
      }
      else {
        if(this.state.pos+1>=this.numSlides)
          this.setState({pos:0})
        else
          this.setState({pos:this.state.pos+1})
      }
  }
    /*
    setPos triggers the changing of slides, by modifiying the pos state
    */
    setPos(i) {
        this.setState({
          pos:i,
          interrupt:true
        })
    }
    
    /*
    The switch is css based
    */
    render() {
        return(
            <Container id={this.props.id}>
            <Wrapper>
                <ImageWrapper 
                    current={this.state.pos}
                    next={(this.state.pos+1===this.numSlides)?(0):(this.state.pos+1)}
                    prev={(this.state.pos===0)?(this.numSlides-1):(this.state.pos-1)}
                    speed = {this.speed}

                    >
                    {
                    this.itemList.map((Item, i) => (
                        
                        <GatsbyImage className="slide" key={i} image={getImage(Item.src)} alt={Item.alt}/>
                    ))
                    }
                </ImageWrapper>
              </Wrapper>
              
            </Container>
        );
    }
}
export default Banner;