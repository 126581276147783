import React from 'react'
import styled from 'styled-components'

const Container = styled.a`
padding:8px 30px;
border-radius:200px;
border: solid 2px var(--tred);


font-family:var(--headerfont);
font-size:1.5em;
font-weight:bold;

text-decoration:none;
color:var(--twhite);
background-color:var(--tred);

transition: all 0.3s linear;

&:hover {
    color:var(--tred);
    background-color:var(--twhite);
}
`

export default function Button(props) {
    return(
        <Container href={props.link}>{props.children}</Container>
    )
}