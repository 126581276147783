import React, { Component } from 'react';
import AnimateHeight from 'react-animate-height';
import styled from 'styled-components'
import Arrow from '../data/icons/arrow.svg'

const Subcontainer = styled.div`
position:relative;
width:90%;
margin:15px auto;
max-width:1100px;
@media(min-width:601px) {
    width:65%;
}
`

const Main = styled.div`
position:relative;
display:flex;
align-items:center;
justify-content:space-between;
margin:0;
z-index:10;

padding:5px 25px;
background-color:var(--tmain);
border-radius:200px;

font-family:var(--headerfont);
font-size:1.125em;
font-weight:900;

& p {
    text-align:center;
    margin:0 auto;
}

& svg {
    width:40px;
    height:40px;
    flex-shrink:0;
    margin-left:15px;

    transition: transform 0.55s ease;
    transform: ${props => props.dropdown?("rotateZ(180deg)"):("rotateZ(0deg)")};
}

@media(min-width:993px){
    padding:12.5px 25px;
    font-size:1.75em;

    &:hover {
        cursor:pointer;
    }
}
`

const Expandable = styled(AnimateHeight)`
position:relative;
top:-1.275em;
z-index:0;

background-color:var(--tmain-light2);
font-family:var(--textfont);
font-size:1em;
border-radius:0 0 30px 30px;

& div {
    width:80%;
    padding:30px 0 20px 0;
    margin:0 auto;
}

@media(min-width:993px){
    font-size:1.375em;
}
`

class NewsSubcontainer extends Component {
    constructor(props){
        super(props)

        this.state={
            height: 0,
            dropdown: false,
        }
    }
     
    toggle = () => {
    const { height } = this.state;
    
    this.setState({
        height: height === 0 ? 'auto' : 0,
        dropdown: !this.state.dropdown
    });
    };

    render(){
        const { height } = this.state;

        return(
            <Subcontainer>
                <Main dropdown={this.state.dropdown} aria-expanded={ height !== 0 } aria-controls='newsSubcontainer-panel' onClick={ this.toggle }>
                    <p>{this.props.data.title}</p>
                    <Arrow/>
                </Main>
                <Expandable
                    id='newsSubcontainer-panel'
                    duration={ 500 }
                    height={ height } // see props documentation below
                >
                    <div dangerouslySetInnerHTML={{__html: this.props.data.text}}/>
                </Expandable>
            </Subcontainer>
        )
    }
}

export default NewsSubcontainer;