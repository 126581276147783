import React from 'react'
import styled from 'styled-components'
import Bar from '../data/icons/bar.svg'
import Ec from '../data/icons/ec.svg'

const Container = styled.div`
position:relative;
width:100%;
display:flex;
flex-flow:row wrap;
align-items:center;
justify-content:space-around;
color:var(--tgrey);
max-width:1500px;
margin:0 auto;

& h2 {
    font-family:var(--headerfont);
    font-size:1.625em;
}
`

const CostFee = styled.div`
max-width:600px;
margin: 0 20px;

& p {
    font-family:var(--textfont);
    font-size:1.125em;    
}

& div {
    margin: 75px 0;

    & * {
        margin:10px 0;
    }
}

@media(min-width:993px) {
    & p {
        font-size:1.375em;
    }
}
`

const Options = styled.div`
text-align:center;

& p {
    font-family:var(--headerfont);
    font-size:1.5em;
    font-weight:700;
}

& div {
    display:grid;
    grid-template-columns: 1fr 1fr;
    align-items:center;

    & svg {
        width:150px;
        height:auto;
    }
}

@media(min-width:993px) {
    & p {
        font-size:1.875em;
    }

    & svg {
        width:190px;
    }
}
`

export default function Payment(props){
    return(
        <Container>
            <CostFee>
                <div>
                    <h2>{props.data.cost.title}</h2>
                    <p>{props.data.cost.text}</p>
                </div>
                <div>
                    <h2>{props.data.fee.title}</h2>
                    <p><b>{props.data.fee.subtitle}</b></p>
                    <p>{props.data.fee.text}</p>
                </div>
            </CostFee>
            <Options>
                <h2>{props.data.options.title}</h2>
                <div>
                    <Ec/>
                    <p>{props.data.options.ec}</p>
                </div>
                <div>
                    <p>{props.data.options.bar}</p>
                    <Bar/>
                </div>
            </Options>
        </Container>
    )
}