import React from 'react'
import styled from 'styled-components'
import Header from './TitleElement'

const Container = styled.div`
position:relative;
width:100%;
color:var(--tgrey);

@media(min-width:993px) {
    & >div:nth-child(2n){
        flex-direction: row;

        & .image {
            margin-right:100px;
        }
    }

    & >div:nth-child(2n-1){
        flex-direction: row-reverse;
    }
}
`

const SubContainer = styled.div`
position:relative;
display:flex;
flex-direction:column;
align-items:center;
justify-content:center;
margin-top:40px;

& >*{
    margin: 0 50px;
}
`

const ImageContainer = styled.div`
position:relative;
width:85%;
height:85%;
max-width:400px;
margin: 40px 0;

& img {
    width:100%;
    height:100%;

    border-radius:200px;
}

& span {
    display:none;
    position: absolute;
    z-index: -1;
    border-radius: 200px;
    width: 50px;
    height: 50px;
    background-color:var(--tmain-light3);
}

& span:nth-child(2){
    top: -120px;
    left: 140px;
}

& span:nth-child(3){
    top: -25px;
    left: -45px;
    width: 150px;
    height: 150px;
}

& span:nth-child(4){
    top: 290px;
    left: 350px;
    width: 250px;
    height: 250px;
}



@media(min-width:993px) {
    width:400px;
    height:400px;
    margin:0;

    & span {
        display:block;
    }

    & img {
        width:400px;
        height:400px;
    }
}
`

const ContentContainer = styled.div`
position:relative;
width:100%;
max-width:700px;

& h2 {
    font-family:var(--headerfont);
    font-size:1.625em;
    font-weight:bold;
    text-align:center;
}

@media(min-width:993px) {
    & h2 {
        text-align:left;
    }
}
`

const Subitem = styled.div`
display:flex;
align-items:center;
margin: 2px 20px 2px 20px;

& p {
    font-family:var(--textfont);
    font-size:1em;
    margin-left:15px;
}

& img {
    width:30px;
    height:30px;
}

@media(min-width:993px) {
    & p {
        font-size:1.375em;
    }

    & img {
        width:40px;
        height:40px;
    }

    margin: 2px 0;
}
`

const Appointment = styled.div`
background-color:var(--tmain-light2);
color:var(--tgrey);
border-radius:10px;
max-width:500px;
width:90%;

margin: 0 auto;
padding:5px 30px;

& h2 {
    font-family:var(--headerfont);
    font-size:1.625em;
    font-weight:bold;
}

& li {
    font-family:var(--textfont);
    font-size:1.125em;
    margin-bottom:20px;
}

@media(min-width:993px){
    margin:0;
    & div {
        height: auto;
    }
}
`

export default function VetInfo(props) {
    return (
        <Container id={props.id}>
            <Header>{props.data.title}</Header>
            <SubContainer>
                <ImageContainer className="image">
                    <img src={props.data.imageCat.src.publicURL} alt={props.data.imageCat.alt}/>
                </ImageContainer>
                <Appointment dangerouslySetInnerHTML={{__html:props.data.advice}}/>
            </SubContainer>
            <SubContainer>
                <ImageContainer className="image">
                    <img src={props.data.imageDog.src.publicURL} alt={props.data.imageDog.alt}/>
                    <span/>
                    <span/>
                    <span/>
                </ImageContainer>
                <ContentContainer>
                    <h2>{props.data.headline}</h2>
                    {
                        props.data.subitems.map((item, i) => (
                            <Subitem key={i}>
                                <img src={item.icon.src.publicURL} alt={item.icon.alt}/>
                                <p dangerouslySetInnerHTML={{__html:item.text}}></p>
                            </Subitem>
                        ))
                    }
                </ContentContainer>
            </SubContainer>
        </Container>
    )
}