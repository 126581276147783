import React from "react"
import styled from "styled-components"
import Header from "./TitleElement"
import Dog from "../data/icons/dog.svg"
import Hamster from "../data/icons/hamster.svg"
import ModalImageStatic from "./ModalImageStatic"
import ModalImageSlideshow from "./ModalImageSlideshow"


const Container = styled.div`
position:relative;
width:100%;

`
const Portrait = styled.div`
position:relative;
width:100%;
max-width:1400px;
padding-top:100px;
display:flex;
margin:auto;
justify-content:flex-start;

`

const PictureWrapper = styled.div`
position:relative;
display:flex;
flex-direction:column;
width:100%;
max-width:800px;
padding:20px;
margin:auto;
@media (min-width:993px) {
margin:0;
}

font-family:var(--textfont);
& p {
    width:100%;
    text-align:center;
    font-size:1.5em;
    & b {
        font-family:var(--headerfont);
    }
}
`
const Gallery = styled.div`
position:relative;
max-width:1400px;
padding-top:100px;
display:flex;
margin:auto;
align-items:center;
justify-content: flex-end;

& .hamster {
    width:200px;
    height:200px;
    margin:100px 40px 0 0;
    fill:var(--tgrey);
}
`

const BgObject1 = styled.div`
z-index:-1;
& .bgObj {
    display:none;
    position:absolute;
    z-index:-5;
    fill:var(--tmain-light2);
}
& svg:nth-child(1) {
    display:block;
    top:68%;
    left:27%;
    transform:scale(2.2);
}
@media (min-width:993px) {
& .bgObj {
    display:block;
    position:absolute;
    z-index:-5;
    fill:var(--tmain-light2);
}
& svg:nth-child(1) {
    top:68%;
    left:27%;
    transform:scale(2.2);
}
& svg:nth-child(2) {
    top:95%;
    left:12%;
    transform:scale(0.5);
}
& svg:nth-child(3) {
    top:6%;
    left:18%;
    transform:scale(1);
}
& svg:nth-child(4) {
    top:55%;
    left:70%;
    transform:scale(1);
}
& svg:nth-child(5) {
    width:200px;
    height:200px;
    position:absolute;
    top:60%;
    left:70%;
    fill:var(--tgrey);
}
}

`
const BgObject2 = styled.div`

z-index:-1;
& .bgObj {
    display:none;
    position:absolute;
    z-index:-5;
    fill:var(--tmain-light2);
}
& svg:nth-child(3) {
    display:block;
    top:68%;
    left:27%;
    transform:scale(2.2);
}
@media (min-width:993px) {
& .bgObj {
    display:block;
    position:absolute;
    z-index:-5;
    fill:var(--tmain-light2);
}
& svg:nth-child(1) {
    top:68%;
    left:27%;
    transform:scale(2.2);
}
& svg:nth-child(2) {
    top:95%;
    left:12%;
    transform:scale(0.5);
}
& svg:nth-child(3) {
    top:6%;
    left:18%;
    transform:scale(1);
}
& svg:nth-child(4) {
    top:40%;
    left:70%;
    transform:scale(1);
}
& svg:nth-child(5) {
        width:200px;
        height:200px;
        position:absolute;
        top:60%;
        left:8%;
        fill:var(--tgrey);
}
}

`



export default function WhoWeAre(props) {

    const altFirst = props.data.portrait.alt.split(" ",1)
    const altTail = props.data.portrait.alt.replace(altFirst,"")

    return(
        <Container id={props.id}>
            <Header>{props.data.header}</Header>
            <Portrait>
                <PictureWrapper>
                    <ModalImageStatic data={props.data.portrait}/>
                    <p><b>{altFirst}</b>{altTail}</p>
                </PictureWrapper>
                <BgObject1 >
                    <svg className="bgObj" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><circle cx="50" cy="50" r="50"></circle></svg>
                    <svg className="bgObj" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><circle cx="50" cy="50" r="50"></circle></svg>
                    <svg className="bgObj" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><circle cx="50" cy="50" r="50"></circle></svg>
                    <svg className="bgObj" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><circle cx="50" cy="50" r="50"></circle></svg>
                    <Dog className="bgObj"/>
                </BgObject1>
            </Portrait>
            <Gallery>
                <PictureWrapper>
                    <ModalImageSlideshow slides={props.data.slides}/>
                </PictureWrapper>
                <BgObject2 >
                    <svg className="bgObj" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><circle cx="50" cy="50" r="50"></circle></svg>
                    <svg className="bgObj" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><circle cx="50" cy="50" r="50"></circle></svg>
                    <svg className="bgObj" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><circle cx="50" cy="50" r="50"></circle></svg>
                    <svg className="bgObj" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100"><circle cx="50" cy="50" r="50"></circle></svg>
                    <Hamster className="bgObj"/>
                </BgObject2>
            </Gallery>
        </Container>
    )
}
