import React from "react"
import Navbar from "../components/Navbar"
import Sidebar from '../components/Sidebar'
import Banner from '../components/Banner'
import WhoWeAre from '../components/WhoWeAre'
import WelcomeBanner from "../components/WelcomeBanner"
import Services from "../components/Services"
import Payment from "../components/Payment"
import Operation from "../components/Operation"
import VetInfo from "../components/VetInfo"
import TransportInfo from "../components/TransportInfo"
import Spacer from "../components/Spacer"
import Emergency from "../components/Emergency"
import News from "../components/News"
import ClosureAndGrief from "../components/ClosureAndGrief"
import Footer from "../components/Footer"
import Header from "../components/TitleElement"
import { Helmet } from 'react-helmet' 

export const IndexTemplate = ({
    bannerData,
    whoweareData,
    navbar,
    sidebar,
    news,
    welcomeBanner,
    emergency,
    services,
    vetinfo,
    transportinfo,
    payment,
    operation, 
    closureandgrief,
    footer1,
    footer2
    }) => (
        <div>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Tierarztpraxis Dr. Heike Kudlik</title>
                <meta name="description" content="In der Kleintierpraxis Dr. Kudlik in Kirchen (Sieg) behandeln wir alle Arten von Kleintieren, egal ob Hund, Katze, Kaninchen, Meerschweinchen, Frettchen, Hamster, Maus, Ratte oder Vogel."></meta>
                <meta name="keywords" content="Dr. Heike Kudlik, Heike Kudlik, Kudlik, Tier, Tierarzt, Kirchen, Betzdorf, Praxis, Tierarztpraxis, Tierärztin, Hund, Katze, Frettchen, Meerschweinchen, Vogel, Haustier, Behandlung, Hilfe, Verletzung, krank"/>
                <meta name="google-site-verification" content="o8k7kWsT-XkLWtObGMKq1hlATOsQHRyScsiXNY-CV0M" />
                
                <html lang="de"/>
            </Helmet>

            <Banner slides={bannerData} speed={1} id="top"/>
            <Navbar data={navbar} topbar={sidebar}/>
            <Sidebar data={sidebar}/>
            <Spacer/>
            <Header><div dangerouslySetInnerHTML={{__html:navbar.title}}></div></Header>
            <Spacer height="10" unit="px"/>
            <News data={news}/>
            <Spacer/>
            <WelcomeBanner data={welcomeBanner}/>
            <Spacer height="125" unit="px"/>
            <Services data={services} id="services"/>
            <Spacer height="125" unit="px"/>
            <Emergency data={emergency} id="emergency"/>
            <Spacer height="150" unit="px"/>
            <WhoWeAre data={whoweareData} id="about"/>
            <Spacer height="200" unit="px"/>
            <VetInfo data={vetinfo} id="general"/>
            <Spacer height="125" unit="px"/>
            <TransportInfo data={transportinfo}/>
            <Spacer/>
            <Payment data={payment}/>
            <Spacer/>
            <Operation data={operation}/>
            <Spacer height="175" unit="px"/>
            <ClosureAndGrief data={closureandgrief}/>
            <Spacer height="100" unit="px"/>
            <Footer data={footer1} data2={footer2} id="contact"/>
        </div>
)