import React from 'react'
import styled from 'styled-components'
import Header from './TitleElement'
import Tile from './Tile'
import MoreIcon from '../data/icons/moreIcon.svg'
import LessIcon from '../data/icons/lessIcon.svg'


const TileContainer = styled.div`
display: flex;
flex-flow: row wrap;
justify-content: center;
align-items:center;
margin:25px auto 0 auto;

max-width:1250px;

@media(max-width:992px) {
    & div:nth-child(n+${props => props.visibleCount+1}){
        display: ${props => props.expanded?("flex"):("none")};
        transform: scale(0);
        animation-name:popup;
        animation-duration:.3s;
        animation-timing-function:cubic-bezier(.47,1.64,.41,.8);
        animation-fill-mode: forwards;
    }
}

@keyframes popup {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
`

const Button = styled.span`
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
margin: 0 auto;
width:120px;
cursor:pointer;

& svg {
    width: 40px;
    height: 40px;
}

& p {
    font-family: var(--textfont);
    font-size:1.25em;
    margin-left:15px;
}

@media(min-width:993px) {
    display: none;
}
`

class Services extends React.Component{
    constructor(props){
        super(props);
    
        this.visibleCount = 3

        this.state={
            expanded: false
        }
    }


    toggleexpand(){
        this.setState({expanded: !this.state.expanded})
    }

    render(){
        return(
            <div id={this.props.id}>
                <Header>{this.props.data.title}</Header>
                <TileContainer expanded={this.state.expanded} visibleCount={this.visibleCount}>
                {
                    this.props.data.tiles.map((item, i) => (
                        <Tile key={i} data={item} index={i} visibleCount={this.visibleCount}/>
                    ))
                }
                </TileContainer>
                <Button onClick={this.toggleexpand.bind(this)}>
                    { !this.state.expanded && <MoreIcon/> }
                    { this.state.expanded && <LessIcon/> }
                    { !this.state.expanded && <p>mehr</p> }
                    { this.state.expanded && <p>weniger</p> }
                </Button>
            </div>
        )
    }
}

export default Services;