import React from 'react'
import styled from 'styled-components'
import Header from './TitleElement'
import Paw from '../data/icons/pawOperation.svg'
import MoreIcon from '../data/icons/moreIcon.svg'
import LessIcon from '../data/icons/lessIcon.svg'

const ItemContainer = styled.div`
position: relative;
color:var(--tgrey);
margin:auto;
width:75%;

& svg {
    position:absolute;
    display:none;
    top:50%;
    left:50%;
    width:auto;
    height:100%;
    max-width:80%;
    z-index:-1;
    transform: translate(-50%, -50%);
}

& div:nth-child(4n-3){
    & span {
        top:-5%;
        right:-5%;
    }
}

& div:nth-child(4n-2){
    & span {
        top:-15%;
        left:-5%;
    }
}

& div:nth-child(4n-1){
    & span {
        top:10%;
        right:20%;
    }
}

& div:nth-child(4n){
    & span {
        top:20%;
        left:30%;
    }
}

@media(max-width:992px) {
    & div:nth-child(n+${props => props.visibleCount+1}){
        display: ${props => props.expanded?("flex"):("none")};
        opacity:0;
        animation-name:fadein;
        animation-duration:1s;
        animation-timing-function:ease;
        animation-fill-mode: forwards;
    }
}

@keyframes fadein {
    0% {
        display: none;
        opacity: 0;
    }
    1% {
        display: block;
        opacity: 0;
        transform: translateY(40px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@media(min-width:993px) {
    max-width:1000px;

    & svg {
        display:block;
    }
}
`

const Subitem = styled.div`
display:flex;
flex-direction:column;
align-items:center;
margin: 40px 0;
animation-delay:${props => (props.index-props.visibleCount)/2}s;

& p {
    font-family:var(--textfont);
    font-size:1.375em;
    margin-left:15px;
}

@media(min-width:993px) {
    flex-direction:row;
    margin: 25px 0;
}
`

const ImageContainer = styled.div`
position:relative;
width:80px;
height:80px;

& img {
    width:80px;
    height:80px;
}

& span {
    position: absolute;
    z-index: -1;
    border-radius: 200px;
    width: 50px;
    height: 50px;
    background-color:var(--tmain-light3);
}

@media(min-width:993px) {
    & span {
        display: none;
    }
}
`

const Button = styled.span`
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;
margin: 0 auto;
width:120px;
cursor:pointer;

& svg {
    width: 40px;
    height: 40px;
}

& p {
    font-family: var(--textfont);
    font-size: 1.25em;
    margin-left:15px;
}

@media(min-width:993px) {
    display: none;
}
`

class Operation extends React.Component{
    constructor(props) {
        super(props)

        this.visibleCount = 3

        this.state={
            expanded: false,            
        }
    }


    toggleexpand(){
        this.setState({expanded: !this.state.expanded})
    }

    render() {
        return(
            <div>
                <Header>{this.props.data.title}</Header>
                <ItemContainer expanded={this.state.expanded} visibleCount={this.visibleCount}>
                    {
                        this.props.data.subitems.map((item, i) => (
                            <Subitem key={i} index={i} visibleCount={this.visibleCount}>
                                <ImageContainer>
                                    <img src={item.icon.src.publicURL} alt={item.icon.alt}/>
                                    <span/>
                                </ImageContainer>
                                <p>{item.text}</p>
                            </Subitem>
                        ))
                    }
                    <Paw/>
                </ItemContainer>
                <Button onClick={this.toggleexpand.bind(this)}>
                    { !this.state.expanded && <MoreIcon/> }
                    { this.state.expanded && <LessIcon/> }
                    { !this.state.expanded && <p>mehr</p> }
                    { this.state.expanded && <p>weniger</p> }
                </Button>
            </div>
        )
    }
}

export default Operation;