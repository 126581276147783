import React from 'react'
import styled from 'styled-components'
import Header from './TitleElement'
import Rabbit from '../data/icons/rabbit.svg'
import Hamster from '../data/icons/hamster.svg'
import Cat from '../data/icons/cat.svg'
import Dog from '../data/icons/dog.svg'
import Parrot from '../data/icons/parrot.svg'

const Container = styled.div`
position:relative;

& svg {
    width: auto;
    height: 5%;
    margin: 0 3.5px;
}

& p {
    font-family:var(--textfont);
    font-size:1.125em;
    text-align:center;
}

@media(min-width:993px){
    & svg {
        height:5%;
        max-height:128px;
        margin: 0 7.5px;
    }
    
    & p {
        font-size:1.75em;
    }
}
`

const IconContainer = styled.div`
display:flex;
justify-content:center;

margin: 36px 0;
`

export default function WelcomeBanner(props){
    return(
        <Container>
            <Header>{props.data.title}</Header>
            <IconContainer>
                <Rabbit/>
                <Hamster/>
                <Cat/>
                <Dog/>
                <Parrot/>
            </IconContainer>
            <p>{props.data.text}</p>
        </Container>
    )
}